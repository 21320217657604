import React from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

const Root = styled.span`
  color: #000;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  @keyframes border-underline {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  :after {
    position: absolute;
    left: 0;
    bottom: 5px;
    content: "";
    display: block;
    margin: auto;
    height: 3px;
    width: 0;
    background: #000;
    animation: 1s ease 500ms forwards border-underline;
  }
`

const ScrollLink = ({ children, scrollId }) => {
  return <Root onClick={() => scrollTo(`#${scrollId}`)}>{children}</Root>
}

export default ScrollLink
