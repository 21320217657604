import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import SectionContainer from "../components/shared/SectionContainer"
import TextContainer from "../components/shared/TextContainer"
import { Title } from "../components/shared/Headers"
import { graphql } from "gatsby"
import SectionTitle from "../components/shared/SectionTitle"
import SectionSubtitle from "../components/shared/SectionSubtitle"
import SocialIcon from "../components/SocialIcon/SocialIcon"
import { breakpoints } from "../utils/styles"
import ContactForm from "../components/Form/ContactForm"
import ScrollLink from "../components/shared/ScrollLink"

const HeroContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //max-width: 700px;
  width: 100%;
  padding: 7rem 2rem;
`

const StyledTextContainer = styled(TextContainer)`
  //display: flex;
  //flex-direction: column;
`

const StyledTitle = styled(Title)`
  color: #000;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 4.6875rem;
  }
`

const StyledSectionSubtitle = styled(SectionSubtitle)`
  display: inline-block;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const StyledTagline = styled.div`
  align-self: flex-start;
  font-size: 1.5rem;
  line-height: 1.75;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.75rem;
    line-height: 2;
  }

  @media (min-width: ${breakpoints.xxl}) {
    font-size: 2rem;
  }
`

const PhoneNumber = styled.a`
  color: #fff;
  font-family: var(--subheader-font);
  margin-top: 1.25rem;
  display: inline-block;

  :hover {
    text-decoration: none;
  }
`

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 150px;
  margin: 1.25rem auto;
  width: 100%;
`

const QuestionSubtitle = styled.div`
  p {
    margin: 0.5rem 0 0;
    font-size: 1.375rem;
    color: #999999;
    line-height: 1.5;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 1.675rem;
    }
  }
`

const ContactPage = props => {
  const {
    data: {
      prismicContact: {
        data: {
          contact_section_title,
          email,
          phone_number,
          social,
          question_section_title,
          question_section_subtitle,
        },
      },
    },
    location,
  } = props
  return (
    <Layout path={location.pathname}>
      <SEO title="Contact" />
      <HeroContainer>
        <StyledTitle>Let me help</StyledTitle>
        <StyledTagline>
          <span>I'm best as a technical </span>
          <ScrollLink scrollId="contact">co-founder</ScrollLink> or fractional{" "}
          <ScrollLink scrollId="contact">CEO.</ScrollLink>
          <br />
          <span>
            But I also enjoy being an investor, advisor, or just a friend.
          </span>
        </StyledTagline>
      </HeroContainer>
      <SectionContainer background={`#000`}>
        <StyledTextContainer>
          <SectionTitle color="#fff">{contact_section_title.text}</SectionTitle>
          <StyledSectionSubtitle forwardedAs="a" href={`mailto:${email.text}`}>
            {email.text}
          </StyledSectionSubtitle>
          <br />
          <PhoneNumber href="tel:9495734399">{phone_number.text}</PhoneNumber>
          <SocialContainer>
            {social.map(item => (
              <SocialIcon
                key={item.social_type}
                link={item.social_link.url}
                social={item.social_type}
              />
            ))}
          </SocialContainer>
        </StyledTextContainer>
      </SectionContainer>
      <SectionContainer background={`#fff`} id="contact">
        <TextContainer>
          <SectionTitle color="#000">
            {question_section_title.text}
          </SectionTitle>
          <QuestionSubtitle
            dangerouslySetInnerHTML={{ __html: question_section_subtitle.html }}
          />
        </TextContainer>
        <ContactForm />
      </SectionContainer>
    </Layout>
  )
}

export default ContactPage

export const contactQuery = graphql`
  query contactQuery {
    prismicContact {
      data {
        contact_section_title {
          text
        }
        email {
          text
        }
        phone_number {
          text
        }
        social {
          social_type
          social_link {
            url
          }
        }
        question_section_title {
          text
        }
        question_section_subtitle {
          html
        }
      }
    }
  }
`
