import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { ErrorMessage, Formik } from "formik"
import Button from "../Button/Button"
import Input from "./Input"
import { ErrorText, SuccessText } from "./SignUpForm"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  margin: 3rem auto 0;
  align-items: center;
  font-family: var(--body-font);
`

const Select = styled.select`
  width: 100%;
  height: 35px;
  border: none;
  background: #f9f9f9;
  padding: 0.5rem;
`

export const Textarea = styled.textarea`
  padding: 0.5rem;
  margin-top: 1rem;
  width: 100%;
  border: none;
  background: #f9f9f9;
`

const StyledButton = styled(Button)`
  padding: 0.75rem 1rem;
`

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const {
    prismicContact: {
      data: { form_option },
    },
  } = useStaticQuery(OPTIONS_QUERY)

  return (
    <Formik
      initialValues={{ inquiry: "", name: "", email: "", message: "" }}
      validate={values => {
        let errors = {}
        // REGEX
        if (!values.email) {
          errors.email = "Forgot something? Add in your email."
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Try again, that’s not a valid email."
        }
        if (!values.name) {
          errors.name = "Forgot something? Add in your name."
        }
        if (!values.message) {
          errors.message = "Forgot something? Add a brief message"
        }
        if (values.dropdown === "Inquiry Type") {
          errors.dropdown = "Please select one of the options"
        }

        return errors
      }}
      onSubmit={(values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...values }),
        })
          .then(res => {
            console.log(res)
            actions.setStatus({
              success: "Success! We’ll talk soon.. real soon.",
            })
            actions.setSubmitting(false)
          })
          .catch(err => {
            actions.setStatus({
              errorMsg: "There was an error submitting the form.",
            })
            actions.setSubmitting(false)
            console.log(err)
          })
      }}
      render={({ values, handleChange, handleSubmit, status }) => (
        <Form
          onSubmit={handleSubmit}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <Select name="inquiry" value={values.inquiry} onChange={handleChange}>
            <option defaultValue>Inquiry Type</option>
            {form_option.map(i => (
              <option key={i.option.text} value={i.option.text}>
                {i.option.text}
              </option>
            ))}
          </Select>
          <ErrorMessage name="inquiry" component={ErrorText} />
          <Input
            name="name"
            value={values.name}
            onChange={handleChange}
            placeholder="Name"
          />
          <ErrorMessage name="name" component={ErrorText} />
          <Input
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Email"
          />
          <ErrorMessage name="email" component={ErrorText} />
          <Textarea
            name="message"
            value={values.message}
            onChange={handleChange}
            placeholder="Message (Remember to keep it short and sweet please)"
            rows={8}
          />
          {status && <SuccessText>{status.success}</SuccessText>}
          {status && status.errorMsg && (
            <ErrorText>{status.errorMsg}</ErrorText>
          )}
          <ErrorMessage name="message" component={ErrorText} />
          <StyledButton color="#000" type="submit">
            Send
          </StyledButton>
        </Form>
      )}
    />
  )
}

export default ContactForm

const OPTIONS_QUERY = graphql`
  query optionsQuery {
    prismicContact {
      data {
        form_option {
          option {
            text
          }
        }
      }
    }
  }
`
